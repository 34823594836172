<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="title">本馆概况</div>
        <!-- <div class="desc">
            <span>2021-09-12 12:00</span>
            <span>访问量：1000</span>
        </div> -->
        <div class="vHtml" v-html="content ? content.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
        <image :src="image" class="img"></image>
    </div>
</template>

<script>
export default {
    name: 'about',
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '关于本馆',
                },
                {
                    title: '本馆详情',
                },
            ],
            content: '',
            image: '',
        };
    },
    created() {
        this.$hao.getLibrarysDetail(localStorage.getItem('id')).then((res) => {
            this.content = res.data.description;
            this.image = res.data.picUrl;
        });
    },
};
</script>

<style scoped lang="less">
.title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.desc {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 20px;
}
.vHtml {
    margin: 40px 0 54px 0;
}
.img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
</style>
